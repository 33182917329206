import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['curtain', 'card', 'backdrop']

  connect() {
    this.curtain = this.curtainTarget
    this.backdrop = this.backdropTarget
    this.card = this.cardTarget
  }

  handleKeydown(event) {
    if (event.key === 'Escape') {
      this.hideCurtain(null)
    }
  }

  showCurtain(event) {
    event.preventDefault()

    document.body.classList.add('tw-overflow-hidden')
    this.curtain.classList.add('tw-flex')
    this.curtain.classList.remove('tw-hidden')
    this.curtain.setAttribute('aria-hidden', 'false')
    this.curtain.focus()

    // Ensure the backdrop and card are visible before animating
    requestAnimationFrame(() => {
      this.backdrop.classList.replace('tw-opacity-0', 'tw-opacity-100')
      this.card.classList.remove('tw-translate-y-20', 'tw-opacity-0')
      this.card.classList.add('tw-opacity-100')
    })
  }

  hideCurtain(event) {
    if (event) {
      event.preventDefault();
    }

    this.backdrop.classList.replace('tw-opacity-100', 'tw-opacity-0')
    this.card.classList.add('tw-translate-y-20', 'tw-opacity-0')
    this.card.classList.remove('tw-opacity-100')

    // Wait for the animation to finish
    setTimeout(() => {
      this.curtain.classList.remove('tw-flex')
      this.curtain.classList.add('tw-hidden')
      this.curtain.setAttribute('aria-hidden', 'true')
      document.body.classList.remove('tw-overflow-hidden')
    }, 300)
  }
}


