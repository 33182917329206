import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'

/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
export default class extends Controller {
  static values = {
    clientSecret: String,
    publishableKey: String,
    options: Object,
    submitSelector: String,
    formSelector: String,
    radioSelector: String,
    stripeCustomerId: String,
    customerEmail: String,
    paymentMethodId: String,
    spinnerSelector: {
      type: String,
      default: '.spinner-container'
    },
    buttonIconSelector: {
      type: String,
      default: '.button-icon'
    },
    buttonLoadingIconSelector: {
      type: String,
      default: '.button-loading-icon'
    },
    buttonLabelSelector: {
      type: String,
      default: '.button-label'
    }
  }

  static targets = [
    'paymentElement',
    'message',
    'paymentMethodInput',
    'billingAddressElement',
    'linkAuthenticationElement'
  ]

  initialize() {
    this.submitting = false
    this.reallySubmit = false
    this.customerEmail = ''

    this.errorElement = $('#payment-method-errors')
    this.submitButton = $(this.submitOutletElement)
    this.buttonText = this.submitButton.find(this.buttonLabelSelectorValue)

    this.spinnerElement = $(this.spinnerSelectorValue)
    this.spinner = this.submitButton.find(this.buttonLoadingIconSelectorValue)
    this.icon = this.submitButton.find(this.buttonIconSelectorValue)
  }

  get submitOutletElement() {
    return document.querySelector(this.submitSelectorValue)
  }

  get radioOutletElement() {
    return document.querySelector(this.radioSelectorValue)
  }

  async connect() {
    this.stripe = await loadStripe(this.publishableKeyValue)
    this.setupStripeElements()
  }

  insertPaymentMethodHiddenField(field, value) {
    let hiddenField = document.createElement('input')

    hiddenField.type = 'hidden'
    hiddenField.name = 'order[payments_attributes][][source_attributes][' + field + ']'
    hiddenField.value = value

    this.paymentMethodInputTarget.form.appendChild(hiddenField)
  }

  insertBillAddressHiddenField(field, value) {
    let hiddenField = document.createElement('input')

    hiddenField.type = 'hidden'
    hiddenField.name = `order[bill_address_attributes][${field}]`
    hiddenField.value = value

    this.paymentMethodInputTarget.form.appendChild(hiddenField)
  }

  insertGatewayCustomerProfileField() {
    let gatewayCustomerProfileId = this.stripeCustomerIdValue
    if (!gatewayCustomerProfileId) { return }

    let hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = `payment_source[${this.paymentMethodIdValue}][gateway_customer_profile_id]`
    hiddenField.value = gatewayCustomerProfileId

    this.paymentMethodInputTarget.form.appendChild(hiddenField)
  }

  insertPaymentSourceHiddenField(field, value) {
    let hiddenField = document.createElement('input')

    hiddenField.type = 'hidden'
    hiddenField.name = `payment_source[${this.paymentMethodIdValue}][${field}]`
    hiddenField.value = value

    this.paymentMethodInputTarget.form.appendChild(hiddenField)
  }

  insertOrderEmailHiddenField(stripePaymentMethod) {
    let value = stripePaymentMethod.billing_details.email
    let hiddenField = document.createElement('input')

    hiddenField.type = 'hidden'
    hiddenField.name = 'order[email]'
    hiddenField.value = value

    this.paymentMethodInputTarget.form.appendChild(hiddenField)
  }

  addPaymentMethodHiddenFields(stripePaymentMethod) {
    let card = stripePaymentMethod.card

    this.insertPaymentSourceHiddenField('cc_type', card.brand)
    this.insertPaymentSourceHiddenField('last_digits', card.last4)
    this.insertPaymentSourceHiddenField('month', card.exp_month)
    this.insertPaymentSourceHiddenField('year', card.exp_year)
  }

  addBillingAddressHiddenFields(stripePaymentMethod) {
    let billingDetails = stripePaymentMethod.billing_details
    let billingAddress = billingDetails.address

    this.insertBillAddressHiddenField('name', billingDetails.name)
    this.insertBillAddressHiddenField('address1', billingAddress.line1)
    this.insertBillAddressHiddenField('city', billingAddress.city)
    this.insertBillAddressHiddenField('state_abbr', billingAddress.state)
    this.insertBillAddressHiddenField('zipcode', billingAddress.postal_code)
    this.insertBillAddressHiddenField('country_iso', billingAddress.country)
  }

  handleResponse(stripePaymentMethod) {
    // Set email form data
    this.insertOrderEmailHiddenField(stripePaymentMethod)

    // Set Stripe customer profile ID
    this.insertGatewayCustomerProfileField()

    // Set payment form data
    this.addPaymentMethodHiddenFields(stripePaymentMethod)

    // Set billing address form data
    this.addBillingAddressHiddenFields(stripePaymentMethod)

    this.reallySubmit = true
    this.paymentMethodInputTarget.form.submit()
  }

  // Display spinner and disable submit button
  showSpinner() {
    this.spinner.removeClass('!tw-hidden')
    this.submitButton.addClass('disabled')
    this.submitButton.prop('disabled', true)
    this.buttonText.text(
      this.submitButton.data('loadingLabel' || 'Processing...')
    )
    if (this.icon) this.icon.addClass('!tw-hidden')
  }

  // Remove spinner and enable submit button
  hideSpinner() {
    this.spinner.addClass('!tw-hidden')
    this.submitButton.removeClass('disabled')
    this.submitButton.prop('disabled', false)
    this.buttonText.text(this.submitButton.data('label'))
    if (this.icon) this.icon.removeClass('!tw-hidden')
  }

  resetErrorMessages() {
    if (!this.errorElement.length) { return }

    this.errorElement.css('display', 'none')
    this.errorElement.empty()
  }

  handleError(error) {
    const that = this
    // This is a hack to get around some unfortunate other code that's adding
    // the disabled class and disabling this button against our will.
    // Eventually we should look into handling this properly.
    setTimeout(function () {
      that.hideSpinner()
    }, 20)

    this.submitting = false

    if (!this.errorElement.length) { return }
    if (error == undefined || error == null) { return }

    this.errorElement.append(error.message)
    this.errorElement.css('display', 'block')
  }

  // @action
  async handleSubmit(e) {
    e.preventDefault()

    this.resetErrorMessages()

    if (this.reallySubmit) { return true }
    this.setLoading(true)

    // Trigger form validation and wallet collection
    const { error: submitError } = await this.elements.submit()

    if (submitError) {
      console.error(submitError.message)
      this.handleError(submitError)
      return
    }

    // Create the PaymentMethod using the details collected by the Payment Element
    const { error, paymentMethod } = await this.stripe.createPaymentMethod({
      elements: this.elements,
      params: { billing_details: this.billingAddress },
    })

    if (error) {
      console.error(error.message)
      this.handleError(error)
      window.showFlashMessage(
        'error',
        this.errorElement.textContent
      )
      return
    }

    this.setLoading(false)
    this.paymentMethodInputTarget.value = paymentMethod.id
    this.handleResponse(paymentMethod)
  }

  setupLinkAuthenticationElement(stripeElements) {
    // Create link element
    try {
      this.linkAuthenticationElement = stripeElements.create(
        'linkAuthentication',
        {
          defaultValues: {
            email: this.customerEmailValue
          }
        }
      )

      this.linkAuthenticationElement.on('change', (event) => {
        this.customerEmail = event.value.email
      })

      this.linkAuthenticationElement.mount('#link-authentication-element');
    } catch(error) {
      console.log(error)
      this.handleError(error)
    }
  }

  setupBillingAddressElement(stripeElements) {
    // Create billing address element
    try {
      this.billingAddressElement = stripeElements.create(
        'address',
        { mode: 'billing' }
      )

      // Collect billing address values from Stripe element form
      this.billingAddressElement.on('change', (event) => {
        if (event.complete) {
          this.billingAddress = {
            address: {
              line1: event.value.address.line1,
              city: event.value.address.city,
              state: event.value.address.state,
              postal_code: event.value.address.postal_code,
              country: event.value.address.country
            }
          }
        }
      })

      // Display address title if the element was loaded correctly
      this.billingAddressElement.on('loaderstart', function(event) {
        let addressTitle = document.querySelector('#address-form > h5')
        addressTitle.classList.toggle('tw-hidden')
      })

      this.billingAddressElementTarget.innerHTML = ''
      this.billingAddressElement.mount(this.billingAddressElementTarget)
    } catch(error) {
      console.log(error)
      this.handleError(error)
    }
  }

  setupPaymentElement(stripeElements) {
    try {
      this.paymentElement = this.elements.create(
        'payment',
        this.optionsValue.paymentElementCreation
      )
      this.paymentElementTarget.innerHTML = '' // Remove child nodes used for loading
      this.paymentElement.mount(this.paymentElementTarget)
    } catch(error) {
      console.log(error)
      this.handleError(error)
    }
  }

  setupStripeElements() {
    try {
      this.elements = this.stripe.elements(this.optionsValue.elementsInitialization)

      if (!this.customerEmailValue) {
        this.setupLinkAuthenticationElement(this.elements)
      }

      this.setupBillingAddressElement(this.elements)
      this.setupPaymentElement(this.elements)
    } catch(error) {
      console.log(error)
      this.handleError(error)
    }
  }

  setLoading(isLoading) {
    const element = this.submitOutletElement

    if (isLoading) {
      element.setAttribute('disabled', '')
    } else {
      element.removeAttribute('disabled')
    }
  }
}
/* eslint-enable no-undef */
/* eslint-enable no-unused-vars */
