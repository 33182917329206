// Dynamically import all Svelte components from the 'frontend' directory and its subdirectories
const components = import.meta.glob('../frontend/**/*.svelte');

// Function to load the component dynamically
async function loadComponent(componentName) {
  const fileName = `../frontend/${componentName}.svelte`;

  if (components[fileName]) {
    // Lazy load the component
    const component = await components[fileName]();
    return component.default;
  } else {
    console.error(`Svelte component "${componentName}" not found.`);
    return null;
  }
}

// Function to initialize Svelte components in the DOM
function initializeSvelteComponents() {
  document.querySelectorAll('[data-component]').forEach(async (element) => {
    const componentName = element.dataset.component;
    const props = JSON.parse(element.dataset.props || '{}');

    // Dynamically load and mount the Svelte component
    const Component = await loadComponent(componentName);

    if (Component) {
      new Component({
        target: element,
        props: props,
      });
    }
  });
}

// Initialize components on DOM load
document.addEventListener('DOMContentLoaded', () => {
  initializeSvelteComponents();
});

