import { Controller } from '@hotwired/stimulus';

// a controller for the tag pool filter selections state
// responsible for detecting changes to the tags and updating
// their searches
export default class extends Controller {
  static targets = [
    'tagsValue'
  ];

  static values = {
    selectedTags: Array
  }

  connect() {
    this.selectedTagsValue = this.tagsValueTarget.value.split(',');
  }

  add(value) {
    let selectedTags = this.clearLocalTags(this.selectedTagsValue);

    if (selectedTags.length > 0) {
      selectedTags.push(value);
      this.selectedTagsValue = selectedTags;
      this.tagsValueTarget.value = selectedTags.join(',');
    } else {
      this.tagsValueTarget.value = value;
    }
  }

  remove(value) {
    let selectedTags = this.clearLocalTags(this.selectedTagsValue);

    if (selectedTags.length > 1) {
      this.tagsValueTarget.value =
        selectedTags.filter(function (el) {
          return el !== value;
        }).join(',');
    } else {
      this.tagsValueTarget.value = '';
    }
  }

  clearLocalTags(tags) {
    if (tags.length >= 1) {
      return tags.filter(function (el) {
        return el !== ''
      })
    }

    return tags;
  }

  clearSelected(event) {
    this.selectedTagsValue = [];
    this.tagsValueTarget.value = '';
    this.search(event);
  }

  addRemove(event) {
    let value = event.currentTarget.dataset.value;

    if (this.selectedTagsValue.find(tag => tag === value) !== undefined) {
      this.remove(value)
    } else {
      this.add(value)
    }

    this.search(event);
  }

  search(e) {
    e.preventDefault();
    e.stopPropagation();

    this.dispatch('search', e);
  }

  disconnect() {}
}
