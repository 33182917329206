import { Controller } from '@hotwired/stimulus';

// Handle registration modal on explore section
export default class extends Controller {
  static targets = [
    'subscribeModal',
  ]

  static values = {
    showInstantly: Boolean
  }

  // Load necessary elements
  connect() {
    this.buildModal();

    if (this.showInstantlyValue === true) {
      this.showSubscribeModal();
    }
  }

  // Build registration modal
  buildModal() {
    if (this.hasSubscribeModalTarget) {
      /* eslint-disable no-undef */
      this.subscribeModal = new bootstrap.Modal(
        this.subscribeModalTarget,
        { backdrop: true }
      );
      /* eslint-enable no-undef */
    }
  }

  // Display registration modal
  showSubscribeModal() {
    if (this.hasSubscribeModalTarget) {
      this.subscribeModal.show();
    }
  }

  // Hide registration modal
  hideSubscribeModal() {
    if (this.hasSubscribeModalTarget) {
      this.subscribeModal.hide();
    }
  }

  disconnect() {}
}
