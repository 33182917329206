import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'button',
    'collapse'
  ]

  connect() {
    this.setCollapseElement()
  }

  setCollapseElement() {
    this.collapse = new window.bootstrap.Collapse(this.collapseTarget, {
      toggle: false
    })
  }

  toggleAriaExpanded(e) {
    if (e.ariaExpanded === 'true') {
      e.ariaExpanded = 'false'
      return
    }

    e.ariaExpanded = 'true'
  }

  clearCollapsedElements(el) {
    const collapseElement = el
    const collapseElements = document.querySelectorAll('.collapse-content')

    collapseElements.forEach(content => {
      let collapse = null;
      let collapseBtn = content.children[0]
      const collapseContainer = content.children[1]

      if (collapseElement !== collapseBtn) {
        if (collapseContainer.classList.contains('show')) {
          collapse = new window.bootstrap.Collapse(
            collapseContainer,
            { toggle: false }
          )
          collapse.hide()
        }

        collapseBtn.ariaExpanded = 'false'
      }
    })
  }

  toggle(e) {
    e.preventDefault()

    this.clearCollapsedElements(e.currentTarget)
    this.collapse.toggle()
    this.toggleAriaExpanded(e.currentTarget)
    this.element.classList.toggle('open')
  }

  showHide(e) {
    this.clearCollapsedElements(e.currentTarget)
    this.toggleAriaExpanded(e.currentTarget)

    if (this.collapse._element.classList.contains('show')) {
      this.collapse.hide()
      return
    }

    this.collapse.show()
  }

  disconnect() {}
}
