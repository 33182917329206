import { Controller } from '@hotwired/stimulus';

// Handle content row checkbox selection and download button actions
export default class extends Controller {
  static targets = [
    'selectedContents',
    'downloadContents',
    'contentCredits'
  ]

  static values = {
    selectedContent: Array
  }

  connect() {
    this.loadSelectedContent();
  }

  // Load selected content
  loadSelectedContent() {
    this.selectedContent = document.querySelectorAll('.check-content-link.active');
  }

  // Handle checbox actions check/uncheck
  checkUncheck(e) {
    e.preventDefault();

    const el = e.currentTarget;

    if (el.classList.contains('active')) {
      el.classList.remove('active');
    } else {
      el.classList.add('active')
    }
  }

  // Handle clear selected content
  clearContents(samples) {
    if (samples.length >= 1) {
      let filteredSamples = samples.filter(function (el) {
        return el !== ''
      })

      return filteredSamples;
    }

    return samples;
  }

  // Remove selected content to params
  removeContent(value) {
    let selectedContent = this.selectedContentsTarget.value.split(',');
    let filteredSamples = this.clearContents(selectedContent);

    if (filteredSamples.length > 1) {
      this.selectedContentsTarget.value =
      filteredSamples.filter(function (el) {
        return el != value;
      }).join(',');
    } else {
      this.selectedContentsTarget.value = '';
    }
  }

  // Add selected content to params
  addContent(value) {
    let selectedContent = this.selectedContentsTarget.value.split(',');
    let filteredSamples = this.clearContents(selectedContent);

    if (filteredSamples.length > 0) {
      filteredSamples.push(value);
      this.selectedContentsTarget.value = filteredSamples.join(',');
    } else {
      this.selectedContentsTarget.value = value;
    }
  }

  // Handle download button based on the selected content
  enableDisableDownloadBtn() {
    const selectedContent = this.clearContents(
      this.selectedContentsTarget.value.split(',')
      );

    this.contentCreditsTarget.style.display = 'none';

    switch (selectedContent.length) {
    case 0:
      this.downloadContentsTarget.style.display = 'none';
      this.contentCreditsTarget.style.display = 'flex';
      break;
    case 1:
      this.downloadContentsTarget.innerHTML = `Download 1 Sound`;
      this.downloadContentsTarget.style.display = 'block';
      break;
    default:
      this.downloadContentsTarget.style.display = 'block';
      this.downloadContentsTarget.innerHTML = `Download ${selectedContent.length} Sounds`;
    }
  }

  // Unselect single content selection
  unselectRow(e) {
    const element = e.currentTarget;

    this.removeContent(element.dataset.value);
    element.parentElement.parentElement.classList.remove('selected');
  }

  // Handle single content selection
  selectRow(e) {
    const element = e.currentTarget;

    this.addContent(element.dataset.value);
    element.parentElement.parentElement.classList.add('selected');
  }

  // Handle single or multiple content selection
  toggleRow(e) {
    const currentTarget = e.currentTarget;

    if (this.isChecked(currentTarget)) {
      this.selectRow(e);
    } else {
      this.unselectRow(e);
    }

    this.enableDisableDownloadBtn()
  }

  // Verify if element is checked
  isChecked(target) {
    return target.classList.contains('active')
  }

  // Display download content tooltip
  showTooltip(e) {
    const el = e.currentTarget;
    const creditTransactionTooltipTemplate = el.parentElement.children[0];

    creditTransactionTooltipTemplate.classList.add('active')
  }

  // Remove download content tooltip
  hideTooltip(e) {
    const el = e.currentTarget;
    const creditTransactionTooltipTemplate = el.parentElement.children[0];

    creditTransactionTooltipTemplate.classList.remove('active')
  }

  disconnect() {}
}
