// This script is responsible for injecting the CSRF token dynamically into forms
// before they are submitted. This is necessary because the forms are cached
// without the CSRF token to allow page caching. CSRF tokens must always be
// fresh for security reasons, preventing CSRF attacks where a malicious site
// could forge requests using a user's credentials. Without this, old tokens
// could be sent, causing invalid authenticity token errors.

function initializeCsrfTokenInjector() {
  // Select all forms that need to have their CSRF tokens dynamically injected
  const forms = document.querySelectorAll("form");

  // Iterate through each form and attach the submit event listener
  forms.forEach(form => {
    form.addEventListener("submit", function(event) {
      try {
        injectCsrfToken(form); // Attempt to inject the CSRF token before submission
      } catch (error) {
        console.error("Error injecting CSRF token into form submission:", error);
        // You can handle the error here (e.g., notify the user or log it elsewhere)
      }
    });
  });
}

// Function to inject the CSRF token into a form before submission
function injectCsrfToken(form) {
  // Retrieve the CSRF token from the meta tag in the document head
  const csrfToken = document.querySelector('meta[name="csrf-token"]');

  if (!csrfToken) {
    throw new Error("CSRF token not found in meta tags.");
  }

  const tokenValue = csrfToken.getAttribute("content");

  // Find the existing CSRF hidden input field in the form, if it exists
  let csrfField = form.querySelector('input[name="authenticity_token"]');

  // If the CSRF field doesn't exist, create a new hidden input field for it
  if (!csrfField) {
    csrfField = document.createElement("input");
    csrfField.type = "hidden";
    csrfField.name = "authenticity_token";
    form.appendChild(csrfField);
  }

  // Set or update the CSRF token value in the form
  csrfField.value = tokenValue;

  console.log("CSRF token successfully injected into form before submission.");
}

document.addEventListener("DOMContentLoaded", initializeCsrfTokenInjector);
