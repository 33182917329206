import { Controller } from '@hotwired/stimulus';

/* eslint-disable no-undef */
export default class extends Controller {
  static values = {
    showTime: {
      type: Number,
      default: 4000
    },
    fadeOutTime: {
      type: Number,
      default: 1000
    }
  }

  connect() {
    this.displayFlashMessage()
    this.buildFlashMessage()
  }

  displayFlashMessage() {
    const flashMessage = $('.flash')

    if (flashMessage.length) {
      setTimeout((function() {
        flashMessage.fadeOut(this.fadeOutTimeValue)
      }.bind(this)), this.showTimeValue)
    }
  }

  buildFlashMessage() {
    this.buildShowFlashMessage()
    this.buildRemoveFlashMessage()
  }

  buildShowFlashMessage() {
    window.showFlashMessage = function(type, message) {
      if (type == undefined || type == '') { return }
      if (message == undefined || message == '') { return }

      const flashWrapper = $('.js-flash-wrapper')
      const flashDiv = $(`<div class="flash ${type}" />`)

      flashWrapper.append(flashDiv)

      flashDiv.html(message)
        .show('slow')
        .delay(this.showTimeValue)
        .fadeOut(this.fadeOutTimeValue, 'linear', function() {
          this.remove();
        })
    }.bind(this)
  }

  buildRemoveFlashMessage() {
    window.removeFlashMessage = function() {
      const flashMessage = $('.flash')

      if (flashMessage) { flashMessage.remove() }
    }
  }

  disconnect() {}
}
/* eslint-enable no-undef */
