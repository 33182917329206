import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    let payload = JSON.parse(this.element.dataset.itemPayloadParam)

    this.active_id = payload["active_id"];
    this.video_source = payload["video_source"];

    this.updateView()
  }

  disconnect() {}

  loadLink = (event) => {
    const element = event.currentTarget

    this.video_source = element.dataset.link
    this.active_id = element.id

    this.updateView();
  }

  updateView = () => {
    this.element.querySelector('#vid_frame').src = this.video_source;
    let video_buttons = this.element.querySelectorAll('.vid-item');
    let active_button = this.element.querySelector(`#${this.active_id}`);

    [].forEach.call(video_buttons, function(el) {
      el.classList.remove("active");
    });

    active_button.classList.add('active');
  }
}
