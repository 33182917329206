import { Controller } from '@hotwired/stimulus'
import WaveSurfer from 'wavesurfer.js'

export default class extends Controller {
  connect() {
    this.url = atob(this.element.dataset.url)
    this.type = this.element.dataset.type
    this.buttonContainer = this.element.querySelector('#audio-player-button')
    this.thumbnailContainer = this.element.querySelector('#audio-thumbnail')
    this.initialized = false;

    this.options = {}

    switch (this.type) {
      case 'stream_row_style':
        this.options = this.streamRowOptions(this.options)
        break
      default:
        this.options = this.ecomOptions(this.options)
    }

    this.wavesurfer = WaveSurfer.create({
      container: this.thumbnailContainer,
      // this needs to remain web audio, media element puts the
      // url right onto the page
      backend: 'MediaElement',
      waveColor: this.options['waveColor'],
      progressColor: this.options['progressColor'],
      cursorWidth: 0,
      hideScrollbar: false,
      autoCenter: true,
      fillParent: true,
      responsive: true,
    })

    this.wavesurfer.on('finish', function () {
      this.stopPlayback()
    }.bind(this))

    this.wavesurfer.on('error', function (err) {
      console.error(err)
    }.bind(this))

    if (this.element.offsetParent !== null) {
      this.initialized = true;
      this.wavesurfer.load(this.url)
    }

    window.addEventListener('ma_audio_playback_start', this.maPlaybackStartReceived)
    window.addEventListener('tab_clicked', this.responsiveWave)
    window.addEventListener('tab_clicked', this.stopPlayback)
    window.addEventListener('expansion_card_toggled', this.responsiveWave)
    window.addEventListener('expansion_card_toggled', this.stopPlayback)
    window.addEventListener('resize', this.responsiveWave)

    this.responsiveWave();
  }

  disconnect() {
    window.removeEventListener('ma_audio_playback_start', this.maPlaybackStartReceived)
    window.removeEventListener('tab_clicked', this.responsiveWave)
    window.removeEventListener('tab_clicked', this.stopPlayback)
    window.removeEventListener('expansion_card_toggled', this.responsiveWave)
    window.removeEventListener('expansion_card_toggled', this.stopPlayback)
    window.removeEventListener('resize', this.responsiveWave)

    if (this.wavesurfer && this.wavesurfer.isPlaying()) {
      this.stopPlayback()
    }

    this.wavesurfer.destroy()
  }

  ecomOptions(options) {
    options['waveColor'] = '#232323'
    options['progressColor'] = '#845eee'
    return options
  }

  streamRowOptions(options) {
    options['height'] = 28
    options['waveColor'] = '#a3aec2'
    options['progressColor'] = '#845eee'
    return options
  }

  togglePlayback = () => {
    if (!this.initialized) {
      if (this.element.offsetParent !== null) {
        this.initialized = true;
        this.wavesurfer.load(this.url)
      }
    }

    if (this.wavesurfer.isPlaying()) {
      this.stopPlayback()
    } else {
      this.dispatchAudioPlaybackEvent()
      this.startPlayback()
    }
  }

  dispatchAudioPlaybackEvent = () => {
    const event = new Event('ma_audio_playback_start')
    dispatchEvent(event)
  }

  startPlayback = () => {
    if (this.initialized) {
      this.wavesurfer.seekTo(0)
      this.wavesurfer.play()

      if (this.wavesurfer.isPlaying()) {
        this.buttonContainer.innerHTML =
          '<i class="fa fa-pause tw-text-neutral-200"></i>'
      }
    }
  }

  stopPlayback = () => {
    if (this.initialized) {
      this.wavesurfer.pause()
      this.wavesurfer.seekTo(0)

      if (!this.wavesurfer.isPlaying()) {
        this.buttonContainer.innerHTML =
          '<i class="fa fa-play tw-text-neutral-200"></i>'
      }
    }
  }

  maPlaybackStartReceived = (event) => {
    if (event.source !== this) {
      if (this.initialized) {
        this.stopPlayback()
      }
    }
  }

  responsiveWave = () => {
    if (!this.initialized) {
      if (this.element.offsetParent !== null) {
        this.initialized = true;
        this.wavesurfer.load(this.url)
      }
    }

    this.wavesurfer.setHeight(this.getHeight())
  }

  getHeight = () => {
    if (this.options['height']) {
      return this.options['height']
    } else {
      return parseInt(window.getComputedStyle(this.element).height) - 28
    }
  }
}
