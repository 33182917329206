import { Controller } from '@hotwired/stimulus';

// Main class to handle the any search request over explore stimulus controllers
export default class extends Controller {
  static targets = [
    'searchInput',
    'searchClearLink',
    'typeSearchValue',
    'keyRootSearchValue',
    'keyModeSearchValue',
    'genreSearchValue',
    'shuffleValue',
    'categorySearchValue',
    'tagsValue'
  ]

  static values = {
    tooltips: Array
  }

  connect() {
    this.loadTooltips();
    this.keywordSearch();
  }

  // Load tooltips
  loadTooltips() {
    this.tooltips = document.querySelectorAll('.custom-tooltip')
  }

  // Handle keyword search to show/hide clear icon
  keywordSearch() {
    if (!this.hasSearchInputTarget) {
      return;
    }

    if (this.searchInputTarget.value.length > 0) {
      this.searchClearLinkTarget.style.display = 'block';
    } else {
      this.searchClearLinkTarget.style.display = 'none';
    }
  }

  // Clear search text
  clear(e) {
    e.preventDefault();

    this.searchInputTarget.value = '';
    this.keywordSearch();

    if (window.location.search.search('text=') > 0) {
      this.search(e)
    }
  }

  // Validate content params
  validParams(params) {
    let contentType = this.loadContentType(window.location.pathname)

    // only apply params if we're not switching content type
    this.addParam(params, 'text', this.searchInputTarget.value)
    this.addParam(params, 'tags', this.tagsValueTarget.value)
    this.addParam(params, 'shuffle', this.shuffleValueTarget.value)

    // if we're on samples, and targeting samples
    if (contentType === 'samples') {
      this.addParam(params, 'type', this.typeSearchValueTarget.value)
      this.addParam(params, 'category', this.categorySearchValueTarget.value)
      this.addParam(params, 'genre', this.genreSearchValueTarget.value)
      this.addParam(params, 'key_root', this.keyRootSearchValueTarget.value)
      this.addParam(params, 'key_mode', this.keyModeSearchValueTarget.value)
    }

    return params;
  }

  // Handle shuffle action
  randomShuffle(event) {
    this.shuffleValueTarget.value = Math.random();
    this.search(event);
  }

  loadContentType(url) {
    if (url.includes('samples')) {
      return 'samples'
    } else if (url.includes('wavetables')) {
      return 'wavetable'
    } else if (url.includes('current_presets')) {
      return 'current_presets'
    }

    return null;
  }

  // Handle search action
  search(e) {
    e.preventDefault()
    e.stopPropagation()

    let url = window.location.pathname

    // if we're on a packs page, we redirect to content on search
    if (url.endsWith('/packs')) {
      url = url.replace('/packs', '')
    }

    let formParams = new FormData();
    let params = this.validParams(formParams);
    let urlParams = new URLSearchParams(params).toString();

    if (urlParams !== '') {
      url += '?' + urlParams;
    }

    window.location.href = url;
  }

  disconnect() {
  }

  addParam(params, key, value) {
    if (value !== '' && value !== undefined) {
      params.append(key, value)
    }
  }
}
