import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'form'
  ]

  static values = {
    currentOrderId: String,
    currentOrderToken: String
  }

  connect() {
    this.setOrderDetails();
  }

  submitCheckoutForm(event) {
    if (this.formTarget) {
      // Create a hidden input element with the name 'checkout'
      const checkoutInput = document.createElement('input');
      checkoutInput.type = 'hidden';
      checkoutInput.name = 'checkout';
      checkoutInput.value = 'true';  // You can add a value if needed

      // Append the hidden input to the form
      this.formTarget.appendChild(checkoutInput);

      // Submit the form programmatically
      this.formTarget.submit();
    } else {
      console.log('No form found.');
    }
  }

  setOrderDetails() {
    Spree.current_order_id = this.currentOrderIdValue
    Spree.current_order_token = this.currentOrderTokenValue
  }
}
