import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['manageBtn', 'details']

  toggle() {
    this.detailsTarget.classList.toggle('tw-hidden')

    if (this.manageBtnTarget.textContent.trim() === 'Manage') {
      this.manageBtnTarget.textContent = 'Hide'
    } else {
      this.manageBtnTarget.textContent = 'Manage'
    }
  }
}
