import {Controller} from '@hotwired/stimulus';

// a generic filter controller for a tooltip which has a tag & options which
// can be displayed to users and included in a search
export default class extends Controller {
  static targets = [
    'tooltip',
    'filterContent',
    'filterLink',
    'filterTitleContent',
    'filterValue',
    'resetLinkContent'
  ];

  static values = {
    currentTooltipContent: Object,
    currentTooltipTarget: Object
  }

  connect() {
    // Set link text based on selected filter option
    this.setLinkText();
    // Set reset link based on selected filter option
    this.displayResetLink();
    // Load filter tooltips
    this.loadTooltips();
    // Build tootips
    this.buildTooltip();
    // Add click event listener to hide filter tooltips
    this.clickBinding()
  }

  loadTooltips() {
    if (this.filterContentTarget !== undefined && this.filterContentTarget !== null) {
      this.currentTooltipContent = this.filterContentTarget;
      this.currentTooltipTarget = this.currentTooltipContent.querySelector('.custom-tooltip');
    }
  }

  // Build filter tooltips
  buildTooltip() {
    const that = this;

    this.filterPopover = window.CreatePopper(
      that.filterLinkTarget,
      that.currentTooltipTarget,
      {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 2],
            },
          }
        ]
      }
    );
  }

  // Add click event listener to hide filter tooltips
  clickBinding() {
    const that = this;

    document.addEventListener('click', function (e) {
      const filterLink = e.target.parentElement;

      if (filterLink !== undefined &&
        filterLink !== null &&
        !filterLink.classList.contains('filter-link')) {
        that.hideMenu()
      }
    })
  }

  // Display selected value on filter text content
  setLinkText() {
    if (this.isFilterValuePresent() &&
      this.hasFilterTitleContentTarget) {
      this.filterTitleContentTarget.innerHTML = this.filterValueTarget.value;
    }
  }

  // Display reset link in case that a filter option is selected
  displayResetLink() {
    if (this.isFilterValuePresent() &&
      this.hasResetLinkContentTarget) {
      this.resetLinkContentTarget.style.display = 'block';
    }
  }

  // Display tooltip menu
  showMenu(e) {
    e.preventDefault();
    e.stopPropagation();

    // if we're already showing just hide
    if (this.tooltipTarget.hasAttribute('data-show')) {
      this.hideMenu();
    } else {
      this.hideMenu();
      this.tooltipTarget.setAttribute('data-show', '');
      this.filterPopover.update();
    }
  }

  // Hide tooltip menu
  hideMenu() {
    let activeTooltips = document.querySelectorAll('.custom-tooltip[data-show=""]');

    activeTooltips.forEach(function (el) {
      el.removeAttribute('data-show');
    })
  }

  // Reset the selected filter options
  reset(e) {
    event.preventDefault();

    this.filterValueTarget.value = '';
    this.search(e);
  }

  // Add or remove the selected filter option
  addRemove(e) {
    if (this.isFilterValuePresent() &&
      this.filterValueTarget.value === e.currentTarget.dataset.value) {
      this.filterValueTarget.value = '';
    } else {
      this.filterValueTarget.value = e.currentTarget.dataset.value;
    }

    this.search(e);
  }

  isFilterValuePresent() {
    return (this.hasFilterValueTarget &&
      this.filterValueTarget.value !== '' &&
      this.filterValueTarget.value !== undefined)
  }

  // Dispatch search inside search_controller.js
  search(e) {
    e.preventDefault();
    e.stopPropagation();

    this.dispatch('search', e);
  }

  disconnect() {}
}
