// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import * as activeStorage from '@rails/activestorage';
activeStorage.start()

import $ from 'jquery'
window.$ = $

import jQuery from 'jquery'
window.jQuery = jQuery

import Swiper from 'swiper'
window.Swiper = Swiper

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap

import * as fileSaver from '../packs/file_saver';
window.fileSaver = fileSaver

import { createPopper } from '@popperjs/core'
window.CreatePopper = createPopper

import '../controllers/index'
import '../packs/lity'
import '../packs/popover'
import '../packs/svelte_mounter'
import '../packs/form_inject'

import { createInertiaApp } from '@inertiajs/inertia-svelte';

// Create the Inertia app if the data-page is present
if (document.querySelector('[data-page]')) {
  createInertiaApp({
    resolve: name => import(`../frontend/${name}.svelte`),
    setup({ el, App, props }) {
      new App({ target: el, props });
    },
  });
}
