import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab']

  connect() {
    // Ensure initial state is set
    this.tabTargets.forEach((tab) => {
      tab.setAttribute(
        'aria-selected',
        tab.getAttribute('aria-selected') === 'true'
      )
    })
  }

  select(event) {
    this.tabTargets.forEach((tab) => {
      tab.setAttribute('aria-selected', 'false')
    })
    event.currentTarget.setAttribute('aria-selected', 'true')

    const tab_click_event = new Event('tab_clicked')
    dispatchEvent(tab_click_event)
  }

  deselect() {
    this.element.setAttribute('aria-selected', 'false')
  }
}
