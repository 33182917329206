import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'showMoreBg']

  toggleFeatures() {
    this.containerTarget.classList.toggle('tw-max-h-[572px]')
    this.showMoreBgTarget.classList.toggle('tw-opacity-0')
  }
}
