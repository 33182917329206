import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'videoPlayer',
    'playPauseIcon'
  ]

  initialize() {
    this.playPromise = undefined;
  }

  connect() {
    let that = this;

    this.playPromise = async () => {
      await this.videoPlayerTarget.play();
    }

    this.playPromise().then(function () {
      that.setPauseIcon();
    }).catch(function () {
      that.setPlayIcon();
    })
  }

  setPauseIcon() {
    this.playPauseIconTarget.classList.remove('fa-play');
    this.playPauseIconTarget.classList.add('fa-pause');
  }

  setPlayIcon() {
    this.playPauseIconTarget.classList.remove('fa-pause');
    this.playPauseIconTarget.classList.add('fa-play');
  }

  play() {
    this.setPauseIcon();
    this.videoPlayerTarget.play();
  }

  pause() {
    this.setPlayIcon();
    this.videoPlayerTarget.pause();
  }

  playPause() {
    if (this.videoPlayerTarget.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  disconnect() {
  }
}
