import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    window.addEventListener('scroll', this.handleScroll.bind(this))
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll.bind(this))
  }

  handleScroll() {
    if (window.scrollY >= 100) {
      this.element.classList.remove('!tw-hidden')

      setTimeout(() => {
        requestAnimationFrame(() => {
          this.element.classList.add('tw-translate-y-0')
          this.element.classList.remove('-tw-translate-y-20')
        })
      }, 10)
    } else {
      if (!this.element.classList.contains('!tw-hidden')) {
        this.element.classList.add('-tw-translate-y-20')
        this.element.classList.remove('tw-translate-y-0')

        setTimeout(() => {
          this.element.classList.add('!tw-hidden')
        }, 300) // Should be lightly longer than the transition duration
      }
    }
  }
}
