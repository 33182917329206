import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'price',
    'monthlyBtn',
    'annualBtn',
    'monthlyPlan',
    'yearlyPlan',
    'storeMonthlyCredits',
    'storeYearlyCredits'
  ]

  static values = {
    monthly: {
      type: String,
      default: '15'
    },
    annual: {
      type: String,
      default: '120'
    },
    currency: {
      type: String,
      default: '$'
    },
  }

  connect() {
    this.monthlyPrice = this.monthlyValue
    this.annualPrice = this.annualValue
    this.priceCurrency = this.currencyValue

    if (!this.hasPriceTarget) { return }

    this.switchMonthly()
  }

  switchMonthly() {
    this.priceTarget.textContent = `${this.priceCurrency}${this.monthlyPrice}/`

    this.monthlyBtnTarget.classList.add('selected')
    this.annualBtnTarget.classList.remove('selected')
    this.monthlyPlanTarget.classList.remove('tw-hidden')
    this.yearlyPlanTarget.classList.add('tw-hidden')
    this.storeMonthlyCreditsTarget.classList.remove('tw-hidden')
    this.storeYearlyCreditsTarget.classList.add('tw-hidden')
  }

  switchAnnually() {
    this.priceTarget.textContent = `${this.priceCurrency}${this.annualPrice}/`

    this.monthlyBtnTarget.classList.remove('selected')
    this.annualBtnTarget.classList.add('selected')
    this.monthlyPlanTarget.classList.add('tw-hidden')
    this.yearlyPlanTarget.classList.remove('tw-hidden')
    this.storeMonthlyCreditsTarget.classList.add('tw-hidden')
    this.storeYearlyCreditsTarget.classList.remove('tw-hidden')
  }
}
