import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['section']

  connect() {
    this.header = document.querySelector('#product-header')
    if (this.header == undefined || this.header == null) { return; }

    this.updateHeaderHeight()
    // Recalculate header height when it becomes visible.
    // We need this to avoid race condition, because the header is hidden initially.
    this.resizeObserver = new ResizeObserver(this.updateHeaderHeight.bind(this))
    this.resizeObserver.observe(this.header)
  }

  disconnect() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }

  updateHeaderHeight() {
    this.headerHeight = this.header.offsetHeight
  }

  scroll(event) {
    event.preventDefault()
    const link = event.currentTarget
    const sectionId = link.href.split('#')[1]
    const section = document.getElementById(sectionId)

    if (section) {
      // Delay scrolling to allow header to become visible if needed
      setTimeout(() => {
        this.updateHeaderHeight()
        const offset = this.headerHeight + 48 // Add some extra padding
        let targetPosition

        if (section.id === 'hero') {
          targetPosition = 0
        } else {
          targetPosition =
            section.getBoundingClientRect().top + window.pageYOffset - offset
        }

        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        })
      }, 50) // Adjust this delay if needed
    }
  }
}
