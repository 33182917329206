import { Controller } from '@hotwired/stimulus';
import anime from 'animejs/lib/anime.es.js';

export default class extends Controller {
  initialize() {
    let noLoopTimeline = anime.timeline({loop: false});

    noLoopTimeline
      .add({
        targets: this.element,
        translateX: [40,0],
        translateZ: 0,
        opacity: [0,1],
        easing: "easeOutExpo",
        duration: 1200,
        delay: (el, i) => 500 + 30 * i
      });
  }  

  disconnect() {}
}
