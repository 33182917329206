import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal', 'card', 'backdrop']

  connect() {
    this.modal = this.modalTarget
    this.formID = this.data.get('form-id')
    this.primaryAction = this.data.get('primary-action')

    // Listen for the modal form submission if there's one
    if (this.primaryAction && this.formID) {
      this.modal.addEventListener(
        this.primaryAction,
        this.submitForm.bind(this)
      )
    }
  }

  disconnect() {
    // Remove the event listener if it exists
    if (this.modal && this.primaryAction && this.formID) {
      this.modal.removeEventListener(
        this.primaryAction,
        this.submitForm.bind(this)
      )
    }
  }

  showModal(event) {
    event.preventDefault()
    document.body.classList.add('tw-overflow-hidden')
    this.modal.classList.add('tw-flex')
    this.modal.classList.remove('tw-hidden')
    this.modal.setAttribute('aria-hidden', 'false')
    this.modal.focus()

    // Ensure the backdrop and card are visible before animating
    requestAnimationFrame(() => {
      this.backdropTarget.classList.replace('tw-opacity-0', 'tw-opacity-100')
      this.cardTarget.classList.remove('-tw-translate-y-12', 'tw-opacity-0')
      this.cardTarget.classList.add('tw-opacity-100')
    })
  }

  hideModal() {
    this.backdropTarget.classList.replace('tw-opacity-100', 'tw-opacity-0')
    this.cardTarget.classList.add('-tw-translate-y-12', 'tw-opacity-0')
    this.cardTarget.classList.remove('tw-opacity-100')

    // Wait for the animation to finish
    setTimeout(() => {
      this.modal.classList.remove('tw-flex')
      this.modal.classList.add('tw-hidden')
      this.modal.setAttribute('aria-hidden', 'true')
      document.body.classList.remove('tw-overflow-hidden')
    }, 300)
  }

  handleKeydown(event) {
    if (event.key === 'Escape') {
      this.hideModal()
    }
  }

  handlePrimaryAction(event) {
    event.preventDefault()

    if (this.primaryAction) {
      const customEvent = new CustomEvent(this.primaryAction, {
        detail: { modalID: this.modal.id, formID: this.formID },
        bubbles: true
      })

      this.modal.dispatchEvent(customEvent)
    }
  }

  submitForm(event) {
    const formID = event.detail.formID
    const form = document.getElementById(formID)

    if (form) {
      form.submit()
    }
  }
}
