import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['allAccessScrollItem']

  connect() {
    // Store the initial top position of the section
    this.sectionTop = this.element.getBoundingClientRect().top + window.scrollY
    this.scrollHandler = this.handleScroll.bind(this)

    // Use IntersectionObserver to trigger animations only when the section is visible
    this.observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          window.addEventListener('scroll', this.scrollHandler)
        } else {
          window.removeEventListener('scroll', this.scrollHandler)
        }
      },
      { threshold: 0.4 } // Start observing when 10% of the section is visible
    )
    this.observer.observe(this.element)

    // Initial stacking of all rows
    this.initialStack()
  }

  disconnect() {
    window.removeEventListener('scroll', this.scrollHandler)
    this.observer.disconnect()
  }

  initialStack() {
    // Stack all rows behind the first one
    this.allAccessScrollItemTargets.forEach((item, index) => {
      if (index > 0) {
        item.style.transform = `translateY(-${index * 100}%)`
      }
    })
  }

  handleScroll() {
    // Calculate scroll position relative to the section
    const scrollPosition = window.scrollY - this.sectionTop
    const viewportHeight = window.innerHeight

    // Handle second and third rows
    this.allAccessScrollItemTargets.slice(1).forEach((item, index) => {
      const threshold = parseInt(item.dataset.translateThreshold)

      if (scrollPosition + viewportHeight > threshold) {
        // Move the item down to its original position
        item.style.transform = 'translateY(0)'
      } else {
        // Keep the item stacked behind the previous row
        item.style.transform = `translateY(-${(index + 1) * 100}%)`
      }
    })
  }
}
