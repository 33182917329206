import { Controller } from '@hotwired/stimulus';
import { FetchRequest } from '@rails/request.js';

export default class extends Controller {
  static targets = [
    'favoriteTooltip',
    'favoriteContent',
    'favoriteTooltipInner',
    'registrationModal',
    'contentRow'
  ]

  connect() {}

  showTooltip(e) {
    e.preventDefault();

    this.favoriteTooltipTarget.classList.add('active')
  }

  hideTooltip(e) {
    e.preventDefault();

    this.favoriteTooltipTarget.classList.remove('active')
  }

  favorite(e) {
    e.preventDefault();

    const el = e.currentTarget;

    if (el.dataset.state === 'add') {
      this.addFavorite(el);
    } else {
      this.removeFavorite(el);
    }
  }

  updateTooltip(state = '') {
    if (state === 'add') {
      this.favoriteTooltipTarget.classList.add('enable');
      this.favoriteTooltipInnerTarget.innerHTML = 'Remove from Favorites'
    } else {
      this.favoriteTooltipTarget.classList.remove('enable');
      this.favoriteTooltipInnerTarget.innerHTML = 'Add to Favorites'
    }
  }

  removeContentRow() {
    if (!window.location.pathname.includes('/favorites')) { return; }

    this.contentRowTarget.remove();
  }

  updateState(action = 'add', element = undefined, result = {}) {
    if (action === 'add') {
      element.classList.add('active');
      element.dataset.state = 'remove';
      this.updateTooltip('add');
      element.dataset.favoriteId = result.id;
    } else {
      element.classList.remove('active');
      element.dataset.state = 'add';
      this.updateTooltip('remove');
      element.dataset.favoriteId = '';
      this.removeContentRow();
    }
  }

  addFavorite(element) {
    const that = this;

    let url = '';
    let favorableId = element.dataset.favorableId;
    let favorableType = element.dataset.favorableType;
    url += '/stream/favorites?favorable_id=' + favorableId;
    url += '&favorable_type=' + favorableType;
    let request = new FetchRequest('post', url)

    request.perform()
      .then(response => {
        response.text
          .then(result => { // eslint-disable-line no-unused-vars
            if (response.redirected) {
              window.location.href = response.response.url;
              return;
            }

            response.text
              .then(result => {
                const parsedResult = JSON.parse(result);
                const contentName = element.dataset.name;

                switch (response.statusCode) {
                  case 200:
                    that.updateState('add', element, parsedResult);
                    window.showFlashMessage(
                      'success',
                      `${contentName} Was Added To Your Favorites`
                    )
                    return;
                  case 401:
                    that.showRegistrationModal();
                    return;
                  default:
                    // Avoid to print the error on the browser console
                    // console.log(parsedResult)
                    window.showFlashMessage(
                      'error',
                      'Something went wrong, please try again later'
                    )
                }
              });
          })
      })
  }

  showRegistrationModal() {
    let registrationModal = document.getElementById('registration-modal');

    const userValidationController = this.application.getControllerForElementAndIdentifier(
      registrationModal,
      'user-validation'
    );
    userValidationController.showRegistrationModal();
  }

  removeFavorite(element) {
    let favoriteId = element.dataset.favoriteId;
    let url = '/stream/favorites/' + favoriteId;
    let request = new FetchRequest('delete', url);

    request.perform()
      .then(response => {
        response.text
          .then(result => {
            if (response.redirected) {
              window.location.href = response.response.url;
              return;
            }

            const parsedResult = JSON.parse(result);
            const contentName = element.dataset.name;

            if (response.ok) {
              this.updateState(
                'remove',
                element,
                parsedResult
              );

              window.showFlashMessage(
                'success',
                `${contentName} Was Removed From Your Favorites`
              )
            } else {
              // Avoid to print the error on the browser console
              // console.log(parsedResult);
              window.showFlashMessage(
                'error',
                'Something went wrong, please try again later'
              )
            }
          })
      })
  }

  disconnect() {}
}
