import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'bundleProducts',
    'bundleImage',
    'bundleContainer',
    'bundleCaption',
    'bundlePriceDetails',
    'bundleDetailsBtn',
  ]
  isShown = false

  toggleBundleDetails() {
    this.isShown = !this.isShown
    this.bundleProductsTarget.classList.toggle('tw-hidden')
    this.bundleImageTarget.classList.toggle('tw-hidden')
    this.bundleCaptionTarget.classList.toggle('tw-hidden')
    this.bundlePriceDetailsTarget.classList.toggle('tw-hidden')

    // Swap background class based on isShown state
    if (this.isShown) {
      this.bundleContainerTarget.classList.add('tw-bg-neutral-950')
      this.bundleContainerTarget.classList.remove('tw-bg-neutral-700')
      this.bundleDetailsBtnTarget.textContent = 'Hide details'
    } else {
      this.bundleContainerTarget.classList.remove('tw-bg-neutral-950')
      this.bundleContainerTarget.classList.add('tw-bg-neutral-700')
      this.bundleDetailsBtnTarget.textContent = "What's included"
    }
  }
}
