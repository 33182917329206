import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'comparePlansModal'
  ]

  static values = {
    showInstantly: Boolean
  }

  connect() {
    this.buildModal()

    if (this.showInstantlyValue === true) {
      this.showModal();
    }
  }

  // Handle modal creation
  buildModal() {
    const that = this;

    if (this.hasComparePlansModalTarget) {
      /* eslint-disable no-undef */
      this.comparePlansModal = new bootstrap.Modal(
        that.comparePlansModalTarget,
        { backdrop: false }
      );
      /* eslint-enable no-undef */
    }
  }

  showModal() {
    if (this.hasComparePlansModalTarget) {
      this.comparePlansModal.handleUpdate()
      this.comparePlansModal.show()
    }
  }

  // Hide registration modal
  hideModal() {
    if (this.hasComparePlansModalTarget) {
      this.comparePlansModal.hide()
    }
  }

  disconnect() {
  }
}
