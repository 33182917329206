import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'emailField',
    'errorMessage',
    'submitBtn'
  ]

  connect() {
    this.spinner = this.submitBtnTarget.querySelector(
      '.button-loading-icon'
    )
  }

  showSpinner() {
    this.submitBtnTarget.disabled = true
    this.spinner.classList.remove('!tw-hidden')
  }

  hideSpinner() {
    this.submitBtnTarget.disabled = false
    this.spinner.classList.add('!tw-hidden')
  }

  showErrorMessage() {
    this.errorMessageTarget.classList.remove('tw-d-none')
  }

  hideErrorMessage() {
    this.errorMessageTarget.classList.add('tw-d-none')
  }

  addErrorAlert(input, message) {
    input.classList.add('error')
    this.errorMessageTarget.innerHTML = message
    this.showErrorMessage()
  }

  inputValidation(e) {
    e.preventDefault()

    if (this.emailFieldTarget.value.length > 0) {
      this.clearErrors()
    }
  }

  emailValidation() {
    if (this.emailFieldTarget.value == '' &&
      this.emailFieldTarget.value != undefined) {
      this.addErrorAlert(
        this.emailFieldTarget,
        "The email can't be blank"
      );
    }
  }

  clearErrors() {
    this.emailFieldTarget.classList.remove('error')
    this.errorMessageTarget.innerHTML = ''
    this.hideErrorMessage()
  }

  submit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.showSpinner()

    const currentTarget = e.currentTarget
    const form = document.getElementById(
      currentTarget.dataset.formId
    )

    try {
      this.clearErrors()
      this.emailValidation()

      if (!this.emailFieldTarget.classList.contains('error')) {
        form.submit()
      } else {
        this.hideSpinner()
      }
    } catch(e) {
      console.error(e)
      this.hideSpinner()
      this.clearErrors()
    }
  }

  disconnect() {}
}
