import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']

  connect() {
    this.buttonText = this.buttonTarget.querySelector('.button-label')
    this.spinner = this.buttonTarget.querySelector('.button-loading-icon')
    this.icon = this.buttonTarget.querySelector('.button-icon')

    this.bindListeners()
    this.setupFormListener()
  }

  bindListeners() {
    if (this.spinner) {
      const that = this
      if (this.spinner.classList.contains('!tw-hidden')) {
        that.setLoading(false)
      } else {
        that.setLoading(true)
      }

      if (this.spinner.classList.contains('!tw-hidden')) {
        return
      }

      setTimeout((function () {
        that.setLoading(false)
      }), 1000)
    }
  }

  setupFormListener() {
    const formId = this.element.dataset.formId

    if (formId) {
      const that = this
      const form = document.getElementById(formId)

      if (form) {
        form.addEventListener('submit', () => {
          that.setLoading(true)
        })
      }
    }
  }

  setLoading(loading) {
    if (this.spinner) {
      if (loading) {
        this.spinner.classList.remove('!tw-hidden')

        if (this.buttonText) {
          this.buttonText.textContent = this.buttonTarget.dataset.loadingLabel || 'Processing...'
        }

        if (this.icon) this.icon.classList.add('!tw-hidden')
      } else {
        this.spinner.classList.add('!tw-hidden')

        if (this.buttonText) {
          this.buttonText.textContent = this.buttonTarget.dataset.label
        }

        if (this.icon) this.icon.classList.remove('!tw-hidden')
      }
    }
  }

  disconnect() {

  }
}
