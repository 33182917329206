import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'header',
    'scrollContainer',
    'leftGradient',
    'rightGradient',
    'content',
  ]

  connect() {
    this.syncScroll = this.syncScroll.bind(this)
    this.scrollContainerTarget.addEventListener('scroll', this.syncScroll)
    this.headerTarget.addEventListener('scroll', this.syncScroll)
    this.updateGradientVisibility()
  }

  disconnect() {
    this.scrollContainerTarget.removeEventListener('scroll', this.syncScroll)
    this.headerTarget.removeEventListener('scroll', this.syncScroll)
  }

  syncScroll(event) {
    const scrollLeft = event.target.scrollLeft

    if (event.target === this.scrollContainerTarget) {
      this.headerTarget.scrollLeft = scrollLeft
    } else if (event.target === this.headerTarget) {
      this.scrollContainerTarget.scrollLeft = scrollLeft
    }

    this.updateGradientVisibility()
  }

  updateGradientVisibility() {
    const scrollLeft = this.scrollContainerTarget.scrollLeft
    const scrollWidth = this.contentTarget.offsetWidth
    const containerWidth = this.scrollContainerTarget.offsetWidth
    const maxScroll = scrollWidth - containerWidth

    if (scrollLeft > 0) {
      this.leftGradientTarget.classList.remove('tw-opacity-0')
      this.leftGradientTarget.classList.add('tw-opacity-100')
    } else {
      this.leftGradientTarget.classList.add('tw-opacity-0')
      this.leftGradientTarget.classList.remove('tw-opacity-100')
    }

    if (scrollLeft >= maxScroll) {
      this.rightGradientTarget.classList.add('tw-opacity-0')
      this.rightGradientTarget.classList.remove('tw-opacity-100')
    } else {
      this.rightGradientTarget.classList.add('tw-opacity-100')
      this.rightGradientTarget.classList.remove('tw-opacity-0')
    }
  }
}
