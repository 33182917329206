import { Controller } from '@hotwired/stimulus'

// The sliders actions inside plugins home
export default class extends Controller {
  static targets = ['prevSlide', 'nextSlide', 'carousel']

  connect() {
    if (window.Swiper) {
      this.initCarousel()
    } else {
      console.error('Swiper not loaded.')
    }
  }

  // Load plugins slider
  initCarousel() {
    this.carousel = new window.Swiper(this.carouselTarget, {
      grabCursor: true,
      wrapperClass: 'v2-carousel-wrapper',
      slideClass: 'v2-carousel-slide',
      slidesPerView: 'auto',
      spaceBetween: 8,
      rewind: false,
      loop: false,
      // simulateTouch: false,
      preventClicks: false,
      preventClicksPropagation: false,
      navigation: {
        enabled: true,
        // disabledClass: 'swiper-nav-disabled',
      },
      // slidesOffsetAfter: 320,
      speed: 420,
      breakpoints: {
        640: {
          spaceBetween: 16,
        },
      },
      on: {
        init: (e) => {
          // fake await tick to wait for the slider to be ready
          setTimeout(() => this.updateNavBtns(e), 0)
        },
        reachBeginning: (e) => {
          this.updateNavBtns(e)
        },
        reachEnd: (e) => {
          this.updateNavBtns(e)
        },
        slideChange: (e) => {
          this.updateNavBtns(e)
        },
      },
    })
  }

  // Prevents clicks on sliders that do nothing
  updateNavBtns(carousel) {
    if (carousel.isBeginning) {
      this.prevSlideTarget.setAttribute('disabled', 'disabled')
    } else {
      this.prevSlideTarget.removeAttribute('disabled')
    }

    if (carousel.isEnd) {
      this.nextSlideTarget.setAttribute('disabled', 'disabled')
    } else {
      this.nextSlideTarget.removeAttribute('disabled')
    }
  }

  prevSlide(e) {
    e.preventDefault()
    e.stopPropagation()

    // this.carousel.update()
    this.carousel.slidePrev()
  }

  nextSlide(e) {
    e.preventDefault()
    e.stopPropagation()

    // this.carousel.update()
    this.carousel.slideNext()
  }

  disconnect() {
    if (this.carousel) {
      this.carousel.destroy(true, true)
    }
  }
}
