import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['samples', 'wavetables', 'presets']

  connect() {
    this.showTarget('presets');
  }

  switch(event) {
    let data = event.currentTarget.dataset
    const targetName = data.currentExpansionsSwitcherTarget
    this.showTarget(targetName)
  }

  hideAll() {
    this.samplesTarget.classList.add('tw-hidden')
    this.wavetablesTarget.classList.add('tw-hidden')
    this.presetsTarget.classList.add('tw-hidden')
  }

  showTarget(targetName) {
    this.hideAll()

    const title = this.element.querySelector('#expansions-switcher-content-type')

    if (title) {
      title.innerHTML = targetName
    }

    const target = this.element.querySelector(
      `[data-current--expansions-switcher-target="${targetName}"]`
    )

    if (target) {
      target.classList.remove('tw-hidden')
    } else {
      console.error(`Target ${targetName} not found`)
    }
  }
}
