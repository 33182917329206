import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'container',
    'image',
    'preview',
    'demos',
    'toggleDemosBtn',
    'topShadow',
    'bottomShadow',
    'link',
  ]

  isShown = false

  connect() {
    if (this.hasToggleDemosBtnTarget) {
      if (this.element.dataset.index === '0') {
        this.toggleDemos(false)
      }

      let that = this

      window.addEventListener('expansion_card_toggled', function (e) {
        if (e.detail.source !== that && that.isShown && e.detail.shown) {
          that.toggleDemos();
        }
      });
    }
  }

  toggleDemos(dispatch = true) {
    if (this.hasToggleDemosBtnTarget) {
      this.isShown = !this.isShown

      // Hide stuff
      const elementsToToggle = [
        this.imageTarget,
        this.demosTarget,
        this.previewTarget,
        this.topShadowTarget,
        this.bottomShadowTarget,
      ]
      elementsToToggle.forEach((element) => element.classList.toggle('tw-hidden'))

      // Card link changes
      this.linkTarget.classList.toggle('tw-pointer-events-none')

      // Swap background class based on isShown state
      if (this.isShown) {
        this.containerTarget.classList.add('tw-bg-neutral-950')
        this.containerTarget.classList.remove('tw-bg-neutral-700')
        this.toggleDemosBtnTarget.textContent = 'Hide demos'
      } else {
        this.containerTarget.classList.remove('tw-bg-neutral-950')
        this.containerTarget.classList.add('tw-bg-neutral-700')
        this.toggleDemosBtnTarget.textContent = 'Play demos'
      }

      if (dispatch) {
        const event = new CustomEvent('expansion_card_toggled', {
          detail: {
            shown: this.isShown,
            source: this
          }
        });

        dispatchEvent(event)
      }
    }
  }
}
