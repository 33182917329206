import { Controller } from '@hotwired/stimulus';

// a controller for the key filter on the sample rows
export default class extends Controller {
  static targets = [
    'keyRootValue',
    'keyModeValue',
    'resetLinkContent',
    'filterTitleContent',
    'chordMinorBtn',
    'chordMajorBtn',
    'keyRootOpt'
  ];

  connect() {
    this.setLinkText();
    this.displayResetLink();
  }

  getCurrentOption(type, id) {
    return document.querySelector(`.key-${type}[data-value='${id}']`);
  }

  setKeyRootTitle() {
    if (this.hasKeyRootValueTarget &&
        this.keyRootValueTarget.value !== '' &&
        this.keyRootValueTarget.value !== undefined) {
      let currentOptionValue = this.getCurrentOption('note', this.keyRootValueTarget.value);
      this.filterTitleContentTarget.innerHTML = currentOptionValue.value;
    }
  }

  setKetModeTitle() {
    if (this.hasKeyModeValueTarget &&
        this.keyModeValueTarget.value !== '' &&
        this.keyModeValueTarget.value !== undefined) {
      let currentOptionValue = this.getCurrentOption('mode', this.keyModeValueTarget.value);
      this.filterTitleContentTarget.innerHTML += ' | ' + currentOptionValue.value;
    }
  }

  setLinkText() {
    this.setKeyRootTitle();
    this.setKetModeTitle();
  }

  displayResetLink() {
    if (this.keyRootValueTarget.value !== '' ||
        this.keyModeValueTarget.value !== '') {
      this.resetLinkContentTarget.style.display = 'block';
    }
  }

  reset(e) {
    e.preventDefault();

    this.keyRootOptions = document.querySelectorAll('.circle');
    this.keyRootOptions.forEach(function (el) {
      el.classList.remove('enabled')
    })

    this.chordMinorBtnTarget.classList.remove('enabled');
    this.chordMajorBtnTarget.classList.remove('enabled');
    this.keyRootValueTarget.value = '';
    this.keyModeValueTarget.value = '';

    this.search(e);
  }

  keyRootSearch(e) {
    e.preventDefault();

    if (this.keyRootValueTarget.value === e.currentTarget.dataset.value) {
      this.keyRootValueTarget.value = ''
    } else {
      this.keyRootValueTarget.value = e.currentTarget.dataset.value
    }

    this.search(e);
  }

  keyModeSearch(e) {
    e.preventDefault();

    if (this.keyModeValueTarget.value === e.currentTarget.dataset.value) {
      this.keyModeValueTarget.value = ''
    } else {
      this.keyModeValueTarget.value = e.currentTarget.dataset.value
    }

    this.search(e);
  }

  search(e) {
    e.preventDefault();
    e.stopPropagation();

    this.dispatch('search', e);
  }

  disconnect() {}
}
