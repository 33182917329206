import { Controller } from '@hotwired/stimulus'

// tag pool controller for show / hide management of the extended tag pool
export default class extends Controller {
  static targets = ['tagsPool', 'allTags', 'moreTitle', 'arrowIcon']

  connect() {
    this.clickBinding()
  }

  clickBinding() {
    const that = this

    document.addEventListener('click', function (e) {
      if (
        !e.target.closest('.tags') &&
        !e.target.closest('.tags-tooltip') &&
        that.allTagsTarget.classList.contains('tw-overflow-y-scroll')
      ) {
        that.setTagPoolStyles()
      }
    })
  }

  setTagPoolStyles() {
    if (this.allTagsTarget.classList.contains('tw-overflow-y-scroll')) {
      this.allTagsTarget.classList.remove('tw-overflow-y-scroll')
      this.allTagsTarget.classList.add('tw-overflow-hidden', 'tw-h-7')
      this.setIcon('more')
    } else {
      this.allTagsTarget.classList.remove('tw-overflow-hidden', 'tw-h-7')
      this.allTagsTarget.classList.add('tw-overflow-y-scroll')
      this.setIcon('less')
    }
  }

  setIcon(type = 'less') {
    const button = document.getElementById('more-tags-btn')
    // Need to do something about the icon swap, but Rails ¯\_(ツ)_/¯

    if (type === 'less') {
      button.querySelector('.button-label').textContent = 'Less'
      // button.querySelector('.icon').classList.remove('chevron-down')
      // button.querySelector('.icon').classList.add('chevron-up')
    } else if (type === 'more') {
      button.querySelector('.button-label').textContent = 'More'
      // button.querySelector('.icon').classList.remove('chevron-up')
      // button.querySelector('.icon').classList.add('chevron-down')
    }
  }

  showHide(e) {
    e.preventDefault()

    this.setTagPoolStyles()
  }

  disconnect() {}
}
